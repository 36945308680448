import { LocalPageSettings } from "../@types"
import bounded from "./bounded"

export interface PageSettings {
  /**
   * The maximum console output log level allowed on the page.
   *
   * Expected values (case insensitive, increasing order of verbosity):
   *  - off
   *  - error
   *  - warn
   *  - info
   *  - debug
   */
  consoleLogLevel?: string
  /**
   * The amount of time (in seconds) between application event beacons
   */
  applicationEventsBeaconInterval?: number
  /**
   * The URL used to send application event reports to.
   */
  applicationLoggingEndpoint?: string
  /**
   * The amount of time (in milliseconds) to wait before downloading session config.
   */
  sessionConfigDelay?: number
  /**
   * The amount of time (in seconds) to wait after receiving the session
   * configuration before beginning measurements.
   */
  measurementStartDelay?: number
  /**
   * If given, this is the number of seconds to wait before downloading a fresh
   * configuration.
   */
  sessionRefreshInterval?: number
  /**
   * If given, this overrides the endpoint to which Doppler.js sends beacons.
   */
  dopplerBeaconEndpoint?: string
  /**
   * Override the time to wait between measurement scheduler checks, in seconds.
   */
  schedulerRunInterval?: number
  /**
   * An origin (i.e. scheme, domain and port) used to derive the URL for session
   * configuration downloads. A good use case is developers simulating platform
   * configuration changes.
   */
  sessionConfigOrigin?: string
  rumConfigCountryOverride?: string
  rumConfigStateOverride?: string
  rumConfigASNOverride?: number
}

export const createPageSettings: (source: LocalPageSettings) => PageSettings = (
  s,
) => new PageSettingsImpl(s)

class PageSettingsImpl implements PageSettings {
  private _applicationEventsBeaconInterval?: number
  private _applicationLoggingEndpoint?: string
  private _consoleLogLevel?: string
  private _dopplerBeaconEndpoint?: string
  private _measurementStartDelay?: number
  private _rumConfigCountryOverride?: string
  private _rumConfigStateOverride?: string
  private _rumConfigASNOverride?: number
  private _schedulerRunInterval?: number
  private _sessionConfigDelay?: number
  private _sessionConfigOrigin?: string
  private _sessionRefreshInterval?: number
  constructor(source: LocalPageSettings) {
    /* eslint-disable @typescript-eslint/dot-notation */
    this._applicationEventsBeaconInterval =
      source["applicationEventsBeaconInterval"]
    this._applicationLoggingEndpoint = source["applicationLoggingEndpoint"]
    this._consoleLogLevel = source["consoleLogLevel"]
    this._dopplerBeaconEndpoint = source["dopplerBeaconEndpoint"]
    this._measurementStartDelay = source["measurementStartDelay"]
    this._rumConfigCountryOverride = source["rumConfigCountryOverride"]
    this._rumConfigStateOverride = source["rumConfigStateOverride"]
    this._rumConfigASNOverride = source["rumConfigASNOverride"]
    this._schedulerRunInterval = source["schedulerRunInterval"]
    this._sessionConfigDelay = source["sessionConfigDelay"]
    this._sessionConfigOrigin = source["sessionConfigOrigin"]
    const tempSessionRefreshInterval = source["sessionRefreshInterval"]
    /* eslint-enable @typescript-eslint/dot-notation */
    this._sessionRefreshInterval =
      typeof tempSessionRefreshInterval === "number"
        ? bounded(tempSessionRefreshInterval, 1, 60)
        : undefined
  }
  get consoleLogLevel() {
    return this._consoleLogLevel
  }
  get applicationEventsBeaconInterval() {
    return this._applicationEventsBeaconInterval
  }
  get applicationLoggingEndpoint() {
    return this._applicationLoggingEndpoint
  }
  get sessionConfigDelay() {
    return this._sessionConfigDelay
  }
  get measurementStartDelay() {
    return this._measurementStartDelay
  }
  get sessionRefreshInterval() {
    return this._sessionRefreshInterval
  }
  get dopplerBeaconEndpoint() {
    return this._dopplerBeaconEndpoint
  }
  get schedulerRunInterval() {
    return this._schedulerRunInterval
  }
  get sessionConfigOrigin() {
    return this._sessionConfigOrigin
  }
  get rumConfigCountryOverride() {
    return this._rumConfigCountryOverride
  }
  get rumConfigStateOverride() {
    return this._rumConfigStateOverride
  }
  get rumConfigASNOverride() {
    return this._rumConfigASNOverride
  }
}
